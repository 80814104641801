import React, { useState } from 'react';
import css from './PremiumProfileBio.module.css';
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand, faPlay } from '@fortawesome/free-solid-svg-icons';
import { getYoutubeEmbedUrl, getYoutubeThumbnail } from '../../../util/gallery';
import PlayIconSVG from './youtube-brands.svg';
import ReactHtmlParser from 'react-html-parser';
import { decode } from 'html-entities';

function CustomVideo(props) {
  const { videoUrl } = props;
  const [isLoadable, setLoadable] = useState(false);
  if (isLoadable) {
    return (
      <iframe
        title={'listing video'}
        src={getYoutubeEmbedUrl(videoUrl)}
        className={css.videoIframe}
        allowFullScreen="allowFullScreen"
        frameBorder="0"
      />
    );
  } else {
    return (
      <div className={css.prePlayer}>
        ]
        <img
          className={css.thumbnailImage}
          alt="Vide thumbnail"
          src={getYoutubeThumbnail(videoUrl)}
        />
        <FontAwesomeIcon onClick={() => setLoadable(true)} className={css.playIcon} icon={faPlay} />
      </div>
    );
  }
}

export default function PremiumProfileBio(props) {
  let { title, bio, services, images, videos } = props;

  const items =
    images &&
    images.map((x, index) => {
      return {
        original: x.attributes.variants['scaled-large']
          ? x.attributes.variants['scaled-large'].url
          : '',
        thumbnail: x.attributes.variants['square-small']
          ? x.attributes.variants['square-small'].url
          : '',
        originalAlt: `Listing image ${index + 1}`,
        thumbnailAlt: `Listing image thumbnail ${index + 1}`,
      };
    });

  videos &&
    videos.length > 0 &&
    videos[0] !== '' &&
    videos.forEach(item => {
      if (item.includes('youtu')) {
        items.push({
          thumbnail: getYoutubeThumbnail(item),
          duplicate: PlayIconSVG,
          renderItem: () => images && <CustomVideo videoUrl={item} />,
        });
      }
    });

  return (
    <div className={css.base}>
      <div className={css.bio_title}>{title}</div>
      <div className={css.tags}>
        {services.map((service, index) => {
          if (service !== '') {
            return (
              <Link key={index} to={'/s?pub_type-of-service=' + service}>
                <p className={css.tag}>{service}</p>
              </Link>
            );
          } else {
            return null;
          }
        })}
      </div>
      {images && (
        <ImageGallery
          renderFullscreenButton={(onClick, isActive) => (
            <div className={css.fullscreenButton} onClick={onClick}>
              {isActive ? (
                <FontAwesomeIcon className={css.expandIcon} icon={faCompress} />
              ) : (
                <FontAwesomeIcon className={css.expandIcon} icon={faExpand} />
              )}
            </div>
          )}
          lazyLoad={true}
          showPlayButton={false}
          thumbnailPosition="right"
          items={items}
        />
      )}
      {/*<p className={css.bio}>{bio}</p>*/}
      <div className={[css.bio, 'ql-editor'].join(' ')}>{ReactHtmlParser(decode(bio))}</div>
    </div>
  );
}
