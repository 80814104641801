import React, { useState } from 'react';
import css from './MainDashboardWrapper.css';
import Collapse from '@kunukn/react-collapse';
import {
  Menu as MenuIcon,
  Grid,
  FileText,
  ChevronDown,
  ChevronUp,
  Activity,
  MessageCircle,
  Mail,
  PieChart,
  ChevronLeft,
  Users,
  User,
  CreditCard,
  Settings,
  List,
} from 'react-feather';
import AppLogo from '../../assets/viveka-logo.png';
import { Link, withRouter } from 'react-router-dom';
import config from '../../config';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';
import { logout } from '../../ducks/Auth.duck';
import defaultProfilePic from '../../assets/default_profile.png';

const MainDashboardWrapper = props => {
  const { currentUser, children, title, page, history, logout } = props;

  const [isVCorpOpen, setIsVCorpOpen] = useState(true);
  const [isVCoachOpen, setIsVCoachOpen] = useState(true);
  const [isVClientOpen, setIsVClientOpen] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
  // const myCompanies = currentUser?.attributes?.profile?.metadata[`myCompanies${process.env.REACT_APP_ENVIRONMENT}`];
  // const hasCompany = !!myCompanies
  const currentCompany =
    currentUser?.attributes?.profile?.metadata[
      `currentCompany${process.env.REACT_APP_ENVIRONMENT}`
    ];
  const currentCompanyRoles = currentCompany?.roles || [];

  const menuItemStyles = {
    hover: {
      backgroundColor: '#007373',
      color: 'white',
    },
    active: {
      backgroundColor: '#007373',
      color: 'white',
    },
  };

  return (
    <div className={css.base}>
      <div className={`${css.menu_base} ${isNavOpen && css.menu_open}`}>
        <div className={css.menu_nav_top}>
          {/*<AppLogo className={css.logo} />*/}
          <Link to={'/'} className={css.logo}>
            <img style={{ width: '100%' }} alt={'logo'} src={AppLogo} />
          </Link>
          <ChevronLeft className={css.menu_close_button} onClick={() => setIsNavOpen(false)} />
        </div>

        <div className={css.section_wrapper}>
          {currentCompany &&
            (currentCompanyRoles.includes('admin') || currentCompanyRoles.includes('hr')) && (
              <div className={css.menu_section}>
                <div className={css.menu_item_wrapper} onClick={() => setIsVCorpOpen(!isVCorpOpen)}>
                  <p>V-Corp</p>
                  {isVCorpOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
                <Collapse isOpen={isVCorpOpen}>
                  <a
                    href={`${config.vCorpUrl}/hr/dashboard`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-dashboard' ? css.menu_item_active : ''
                    }`}
                  >
                    <Grid />
                    <p>Dashboard</p>
                  </a>
                  <a
                    href={`${config.vCorpUrl}/hr/curriculum`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-curriculum' ? css.menu_item_active : ''
                    }`}
                  >
                    <FileText />
                    <p>Curriculum</p>
                  </a>
                  <a
                    href={`${config.vCorpUrl}/hr/survey`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-survey' ? css.menu_item_active : ''
                    }`}
                  >
                    <List />
                    <p>Surveys</p>
                  </a>
                  <a
                    href={`${config.vCorpUrl}/hr/coach`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-coach' ? css.menu_item_active : ''
                    }`}
                  >
                    <User />
                    <p>Coach</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/employee`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-employees' ? css.menu_item_active : ''
                    }`}
                  >
                    <Users />
                    <p>Employees</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/billing/budget`}
                    target="_self"
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-billing' ? css.menu_item_active : ''
                    }`}
                  >
                    <CreditCard />
                    <p>Billing</p>
                  </a>

                  <a
                    href={`${config.vCorpUrl}/hr/settings`}
                    className={`${css.collapse_item_wrapper} ${
                      page === 'corp-settings' ? css.menu_item_active : ''
                    }`}
                  >
                    <Settings />
                    <p>Settings</p>
                  </a>
                </Collapse>
              </div>
            )}

          {isProvider && (
            <div className={css.menu_section}>
              <div className={css.menu_item_wrapper} onClick={() => setIsVCoachOpen(!isVCoachOpen)}>
                <p>V-Coach</p>
                {isVCoachOpen ? <ChevronUp /> : <ChevronDown />}
              </div>
              <Collapse isOpen={isVCoachOpen}>
                <Link
                  to={`/v-coach/clients-activities`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-dashboard' ? css.menu_item_active : ''
                  }`}
                >
                  <Grid />
                  <p>Dashboard</p>
                </Link>
                <Link
                  to={`/v-coach/programs`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-program' ? css.menu_item_active : ''
                  }`}
                >
                  <FileText />
                  <p>Programs</p>
                </Link>
                <Link
                  to={`/listings`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-ads' ? css.menu_item_active : ''
                  }`}
                >
                  <Activity />
                  <p>Ads</p>
                </Link>

                <Link
                  to={`/saas-chats/coach`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-messages' ? css.menu_item_active : ''
                  }`}
                >
                  <Mail />
                  <p>Messages</p>
                </Link>

                <Link
                  to={`/referral_program`}
                  className={`${css.collapse_item_wrapper} ${
                    page === 'coach-referral' ? css.menu_item_active : ''
                  }`}
                >
                  <Users />
                  <p>Referrals</p>
                </Link>
              </Collapse>
            </div>
          )}

          <div className={css.menu_section}>
            <div className={css.menu_item_wrapper} onClick={() => setIsVClientOpen(!isVClientOpen)}>
              <p>V-Client</p>
              {isVClientOpen ? <ChevronUp /> : <ChevronDown />}
            </div>
            <Collapse isOpen={isVClientOpen}>
              {currentCompany && (
                <a
                  href={`${config.vCorpUrl}/employee/company/my-curriculums`}
                  className={css.collapse_item_wrapper}
                >
                  <FileText />
                  <p>{currentCompany?.name || 'My Company'}</p>
                </a>
              )}

              <Link
                to={`/v-coach/client/dashboard`}
                className={`${css.collapse_item_wrapper} ${
                  page === 'client-dashboard' ? css.menu_item_active : ''
                }`}
              >
                <Grid />
                <p>Dashboard</p>
              </Link>

              {/* <Link
                to={`/v-coach/client/my-activities`}
                className={`${css.collapse_item_wrapper} ${
                  page === 'client-activity' ? css.menu_item_active : ''
                }`}
              >
                <PieChart />
                <p>My Activities</p>
              </Link> */}
              <Link
                to={`/saas-chats/client`}
                className={`${css.collapse_item_wrapper} ${
                  page === 'client-messages' ? css.menu_item_active : ''
                }`}
              >
                <Mail />
                <p>Messages</p>
              </Link>
            </Collapse>
          </div>

          <hr />
          <div className={css.menu_section}>
            <Link
              to={`/inbox/sales`}
              className={`${css.collapse_item_wrapper} ${
                page === 'coach-inquiry' ? css.menu_item_active : ''
              }`}
            >
              <MessageCircle />
              <p>Inquiries</p>
            </Link>

            <Link
              to={`/account-settings`}
              className={`${css.collapse_item_wrapper} ${
                page === 'all-setting' ? css.menu_item_active : ''
              }`}
            >
              <Settings />
              <p>User settings</p>
            </Link>
          </div>
        </div>
      </div>
      <div className={css.content_base}>
        <div className={css.nav_bar}>
          <MenuIcon onClick={() => setIsNavOpen(true)} />
          <p className={css.nav_title}>{title}</p>
          <div className={css.nav_actions}>
            <Mail
              onClick={() => history.push('/public-messages')}
              className={css.nav_message_button}
            />

            <Menu
              direction="left"
              menuButton={
                <div className={css.avatar_wrapper}>
                  {/*<Avatar src={currentUser?.profilePic}>{avatarName}</Avatar>*/}
                  <div>
                    <img
                      className={css.profile_image}
                      alt="profile"
                      src={
                        currentUser?.profileImage?.attributes?.variants['square-small'].url ||
                        defaultProfilePic
                      }
                    />
                  </div>
                  <div>
                    <p>{currentUser?.attributes?.profile.displayName}</p>
                  </div>
                </div>
              }
            >
              <MenuItem
                styles={menuItemStyles}
                onClick={() => {
                  history.push('/profile-settings');
                  return true;
                }}
              >
                My Profile
              </MenuItem>
              <MenuItem
                styles={menuItemStyles}
                onClick={() => {
                  // logoutA().then(() => history.push('/auth/login'))
                  logout().then(() => history.push('/login'));
                  return true;
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(MainDashboardWrapper)
);
